import React from "react";
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import LogoutIcon from '@mui/icons-material/Logout';

import PasswordIcon from '@mui/icons-material/Password';
import GroupIcon from '@mui/icons-material/Group';
import VideocamIcon from '@mui/icons-material/Videocam';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaidIcon from '@mui/icons-material/Paid';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import SettingsIcon from '@mui/icons-material/Settings';
import ReportIcon from '@mui/icons-material/Report';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import BalanceIcon from '@mui/icons-material/Balance';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { MarkEmailRead, ShoppingCartOutlined } from "@mui/icons-material";
const menus = [
    {
        label: 'sidebar.menu.home',
        type: "section",
        children: [
            {
                uri: "/dashboard",
                label: 'sidebar.menuItem.dashboard',
                type: "nav-item",
                icon: <GraphicEqIcon sx={{ fontSize: 20 }} />
            },
            // {
            //     label: 'sidebar.menuItem.miniadmin',
            //     type: "collapsible",
            //     icon: <GroupIcon sx={{ fontSize: 20 }} />,
            //     children: [
            //         {
            //             uri: "/create-admin",
            //             label: 'sidebar.menuItem.createadmin',
            //             type: "nav-item",
            //             // icon: <AdminPanelSettingsIcon sx={{ fontSize: 20 }} />
            //         },
            //         {
            //             uri: "/manage-admin",
            //             label: 'sidebar.menuItem.manageadmin',
            //             type: "nav-item",
            //             // icon: <AdminPanelSettingsIcon sx={{ fontSize: 20 }} />
            //         },
            //     ]
            // },
            //     {
            //         label: 'sidebar.menuItem.orders',
            //         type: "collapsible",
            //         icon: <InventoryOutlinedIcon sx={{ fontSize: 20 }} />,
            //         children: [
            //             {
            //                 uri: "/pendingorder",
            //                 label: 'sidebar.menuItem.pendingorder',
            //                 type: "nav-item",
            //             },
            //             {
            //                 uri: "/approvedorder",
            //                 label: 'sidebar.menuItem.approvedorder',
            //                 type: "nav-item",
            //             },
            //         ]
            // },
            {
                label: 'sidebar.menuItem.appUsers',
                type: "collapsible",
                icon: <GroupIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/active-users",
                        label: 'sidebar.menuItem.activeusers',
                        type: "nav-item",
                    },
                    {
                        uri: "/inactive-users",
                        label: 'sidebar.menuItem.inactiveusers',
                        type: "nav-item",
                    },
                    // {
                    //     uri: "/stockist-users",
                    //     label: 'sidebar.menuItem.stockistusers',
                    //     type: "nav-item",
                    // },
                ]
            },
            // {
            //     label: 'sidebar.menuItem.products',
            //     type: "collapsible",
            //     icon: <ShoppingCartOutlined sx={{ fontSize: 20 }} />,
            //     children: [
            //         {
            //             uri: "/add-product",
            //             label: 'sidebar.menuItem.addproducts',
            //             type: "nav-item",
            //             icon: <Diversity1Icon sx={{ fontSize: 20 }} />
            //         },
            //         {
            //             uri: "/manage-products",
            //             label: 'sidebar.menuItem.manageproducts',
            //             type: "nav-item",
            //             icon: <Diversity1Icon sx={{ fontSize: 20 }} />
            //         },
            //     ]
            // },
            // {
            //     label: 'NFT Buying Report',
            //     type: "collapsible",
            //     icon: <PaidIcon sx={{ fontSize: 20 }} />,
            //     children: [
            //         // {
            //         //     uri: "/pending-deposit",
            //         //     label: 'Pending',
            //         //     type: "nav-item",
            //         // },
            //         {
            //             uri: "/approved-deposit",
            //             label: 'Approved',
            //             type: "nav-item",
            //         },
            //         // {
            //         //     uri: "/rejected-deposit",
            //         //     label: 'Rejected',
            //         //     type: "nav-item",
            //         // },
            //     ]
            // },
            {
                label: 'Packages Report',
                type: "collapsible",
                icon: <PaidIcon sx={{ fontSize: 20 }} />,
                children: [
                    // {
                    //     uri: "/pending-packages",
                    //     label: 'Pending',
                    //     type: "nav-item",
                    // },
                    {
                        uri: "/approved-packages",
                        label: 'Active',
                        type: "nav-item",
                    }
                ]
            },
            {
                label: 'Rank Achivers',
                type: "collapsible",
                icon: <PaidIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/pending-achievers",
                        label: 'Pending',
                        type: "nav-item",
                    },
                    {
                        uri: "/approved-achievers",
                        label: 'Approved',
                        type: "nav-item",
                    },

                ]
            },
            // {
            //     label: 'Rank Achivers',
            //     type: "collapsible",
            //     icon: <PaidIcon sx={{ fontSize: 20 }} />,
            //     children: [
            //         {
            //             uri: "/pending-achievers",
            //             label: 'Pending',
            //             type: "nav-item",
            //         },
            //         {
            //             uri: "/approved-achievers",
            //             label: 'Approved',
            //             type: "nav-item",
            //         },
            //         {
            //             uri: "/expired-achievers",
            //             label: 'Expired',
            //             type: "nav-item",
            //         },
            //     ]
            // },
            {
                label: 'sidebar.menuItem.payoutdetail',
                type: "collapsible",
                icon: <PaidIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/pending-payout",
                        label: 'sidebar.menuItem.payoutdetailpending',
                        type: "nav-item",
                    },
                    {
                        uri: "/approved-payout",
                        label: 'sidebar.menuItem.payoutdetailapproved',
                        type: "nav-item",
                    },
                    {
                        uri: "/rejected-payout",
                        label: 'sidebar.menuItem.payoutdetailrejected',
                        type: "nav-item",
                    },
                ]
            },
            {
                label: 'sidebar.menuItem.ewallet',
                type: "collapsible",
                icon: <AccountBalanceIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/updateuserbalance",
                        label: 'sidebar.menuItem.creditdebit',
                        type: "nav-item",
                    },
                    {
                        uri: "/credit-history",
                        label: 'sidebar.menuItem.credithistory',
                        type: "nav-item",
                    },
                    {
                        uri: "/debit-history",
                        label: 'sidebar.menuItem.debithistory',
                        type: "nav-item",
                    },
                ]
            },
            // {
            //     label: 'sidebar.menuItem.messages',
            //     type: "collapsible",
            //     icon: <MarkEmailRead sx={{ fontSize: 20 }} />,
            //     children: [
            //         {
            //             uri: "/composemessage",
            //             label: 'sidebar.menuItem.composemessage',
            //             type: "nav-item",
            //         },
            //         {
            //             uri: "/inbox",
            //             label: 'sidebar.menuItem.inbox',
            //             type: "nav-item",
            //         },
            //         {
            //             uri: "/sentmessages",
            //             label: 'sidebar.menuItem.sent',
            //             type: "nav-item",
            //         },
            //     ]
            // },
            {
                label: 'sidebar.menuItem.reports',
                type: "collapsible",
                icon: <ReportIcon sx={{ fontSize: 20 }} />,
                children: [

                    {
                        uri: "/referral-bonus",
                        label: 'sidebar.menuItem.referralbonus',
                        type: "nav-item",
                        icon: <Diversity1Icon sx={{ fontSize: 20 }} />
                    },
                    {
                        uri: "/unilevel-bonus",
                        label: 'Matching Bonus',
                        type: "nav-item",
                        icon: <Diversity1Icon sx={{ fontSize: 20 }} />
                    },
                    // {
                    //     uri: "/stockist-bonus",
                    //     label: 'Stockist Bonus',
                    //     type: "nav-item",
                    //     icon: <Diversity1Icon sx={{ fontSize: 20 }} />
                    // },
                    {
                        uri: "/matching-bonus",
                        label: 'Week Leg Bonus',
                        type: "nav-item",
                        icon: <Diversity1Icon sx={{ fontSize: 20 }} />
                    },
                    // {
                    //     uri: "/pool-bonus",
                    //     label: 'Pool Bonus',
                    //     type: "nav-item",
                    //     icon: <Diversity1Icon sx={{ fontSize: 20 }} />
                    // },
                    // {
                    //     uri: "/rank-bonus",
                    //     label: 'Rank Bonus',
                    //     type: "nav-item",
                    //     icon: <Diversity1Icon sx={{ fontSize: 20 }} />
                    // },
                ]
            },

            {
                label: 'sidebar.menuItem.settings',
                type: "collapsible",
                icon: <SettingsIcon sx={{ fontSize: 20 }} />,
                children: [
                    // {
                    //     uri: "/manage-deposit-wallet",
                    //     label: 'Deposit Accounts',
                    //     type: "nav-item",
                    //     icon: <BalanceIcon sx={{ fontSize: 20 }} />
                    // },

                    {
                        uri: "/manage-packages",
                        label: 'sidebar.menuItem.managepackages',
                        type: "nav-item",
                        icon: <BalanceIcon sx={{ fontSize: 20 }} />
                    },
                    // {
                    //     uri: "/manage-ranks",
                    //     label: 'sidebar.menuItem.manageranks',
                    //     type: "nav-item",
                    //     icon: <BalanceIcon sx={{ fontSize: 20 }} />
                    // },
                    // {
                    //     uri: "/manage-city",
                    //     label: 'sidebar.menuItem.managecity',
                    //     type: "nav-item",
                    //     icon: <BalanceIcon sx={{ fontSize: 20 }} />
                    // },
                    // {
                    //     uri: "/managetransactionpassword",
                    //     label: 'sidebar.menuItem.managetransactionpassword',
                    //     type: "nav-item",
                    //     icon: <Diversity1Icon sx={{ fontSize: 20 }} />
                    // },
                    {
                        uri: "/updatewithdrawal",
                        label: 'sidebar.menuItem.updatewithdrawal',
                        type: "nav-item",
                        icon: <AccountBalanceIcon sx={{ fontSize: 20 }} />
                    },
                    {
                        uri: "/managecommission",
                        label: 'sidebar.menuItem.managecommission',
                        type: "nav-item",
                        icon: <MonetizationOnIcon sx={{ fontSize: 20 }} />
                    },
                    // {
                    //     uri: "/manage-shipping",
                    //     label: 'Shipping Charges',
                    //     type: "nav-item",
                    //     icon: <MonetizationOnIcon sx={{ fontSize: 20 }} />
                    // },
                    // {
                    //     uri: "/managezoom",
                    //     label: 'sidebar.menuItem.managezoom',
                    //     type: "nav-item",
                    //     icon: <VideocamIcon sx={{ fontSize: 20 }} />
                    // },
                    // {
                    //     uri: "/manageunilevelbonus",
                    //     label: 'sidebar.menuItem.manageunilevelbonus',
                    //     type: "nav-item",
                    //     icon: <Diversity1Icon sx={{ fontSize: 20 }} />
                    // },
                    // {
                    //     uri: "/updateloginpassword",
                    //     label: 'sidebar.menuItem.updateloginpassword',
                    //     type: "nav-item",
                    //     icon: <PasswordIcon sx={{ fontSize: 20 }} />
                    // },
                    // {
                    //     uri: "/updatetransactionpassword",
                    //     label: 'sidebar.menuItem.updatetransactionpassword',
                    //     type: "nav-item",
                    //     icon: <PasswordIcon sx={{ fontSize: 20 }} />
                    // },
                    {
                        uri: "/updateuserpassword",
                        label: 'sidebar.menuItem.updateuserpassword',
                        type: "nav-item",
                        icon: <PasswordIcon sx={{ fontSize: 20 }} />
                    },
                ]
            },
            {
                label: 'sidebar.menuItem.kyc',
                type: "collapsible",
                icon: <SupportAgentIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/kycpending",
                        label: 'sidebar.menuItem.kycpending',
                        type: "nav-item",
                    },
                    {
                        uri: "/approvedkyc",
                        label: 'sidebar.menuItem.approvedkyc',
                        type: "nav-item",
                    },
                    {
                        uri: "/rejectedkyc",
                        label: 'sidebar.menuItem.rejectedkyc',
                        type: "nav-item",
                    },
                ]
            },


            {
                label: 'sidebar.menuItem.news',
                type: "collapsible",
                icon: <NewspaperIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/addnews",
                        label: 'sidebar.menuItem.addnews',
                        type: "nav-item",
                        icon: <Diversity1Icon sx={{ fontSize: 20 }} />
                    },
                    {
                        uri: "/managenews",
                        label: 'sidebar.menuItem.managenews',
                        type: "nav-item",
                        icon: <Diversity1Icon sx={{ fontSize: 20 }} />
                    },
                ]

            },
            {
                label: 'sidebar.menuItem.video',
                type: "collapsible",
                icon: <NewspaperIcon sx={{ fontSize: 20 }} />,
                children: [
                    {
                        uri: "/addvideo",
                        label: 'sidebar.menuItem.addvideo',
                        type: "nav-item",
                        icon: <Diversity1Icon sx={{ fontSize: 20 }} />
                    },
                    {
                        uri: "/managevideos",
                        label: 'sidebar.menuItem.managevideo',
                        type: "nav-item",
                        icon: <Diversity1Icon sx={{ fontSize: 20 }} />
                    },
                ]

            },
            {
                uri: "/managepopup",
                label: 'sidebar.menuItem.managepopup',
                type: "nav-item",
                icon: <NewspaperIcon sx={{ fontSize: 20 }} />
            },


            // {
            //     uri: "/profile",
            //     label: 'sidebar.menuItem.updateprofile',
            //     type: "nav-item",
            //     icon: <PasswordIcon sx={{ fontSize: 20 }} />
            // },




            {
                uri: "/logout",
                label: 'sidebar.menuItem.logout',
                type: "nav-item",
                icon: <LogoutIcon sx={{ fontSize: 20 }} />
            },


        ]
    },

];

export default menus;
